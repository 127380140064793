import React from "react";
import {SubgenreSearch} from "../../common/SubgenreSearch";
import {PreferenceButton} from "../../common/PreferenceButton";
import {AllSpotifyGenres} from "../../../SpotifyGenres";
import {Playlist} from "../Playlist";
import './CombinatorialGenres.css';
import {Genre} from "./Genre";

interface CombinatorialSubgenresProps {
    availableGenrePlaylists: Playlist[];
    selectedSubgenres: string[];
    selectedGenres: string[];
    roomForMorePreferences: boolean;
    mostRecentlySelected?: string;
    addGenre: (genre: Genre) => void;
    removeGenre: (genre: Genre) => void;
}

export const CombinatorialGenres = (props: CombinatorialSubgenresProps) => {
    const tooManySelectedPreferencesText = "You cannot select more than 5 genres";
    let selectedSubgenres = props.selectedSubgenres.map((subgenre) => {
        return <PreferenceButton
            mostRecentlyClicked={props.mostRecentlySelected === subgenre}
            roomForMorePreferences={props.roomForMorePreferences}
            active={true}
            addSelectedPreferences={AddSubgenre}
            removeSelectedPreferences={RemoveSubgenre}
            preference={subgenre}
            key={subgenre}
            icon={undefined}
            tooManySelectedPreferencesText={tooManySelectedPreferencesText}
            available={true}/>
    });

    let genreButtons = props.availableGenrePlaylists
        .map(genre =>
            <PreferenceButton
                mostRecentlyClicked={props.mostRecentlySelected === genre.name}
                roomForMorePreferences={props.roomForMorePreferences}
                key={genre.name}
                preference={genre.name}
                addSelectedPreferences={AddGenre}
                removeSelectedPreferences={RemoveGenre}
                active={props.selectedGenres.includes(genre.name)}
                tooManySelectedPreferencesText={tooManySelectedPreferencesText}
                available={true}
            />);

    return (
        <div>
            <div className={"combinatorialGenres"}>
                {genreButtons}
            </div>
            <div className={"combinatorialSubgenres"}>
                <SubgenreSearch addSelectedPreferences={AddSubgenre}
                                allSubgenres={AllSpotifyGenres}
                                selectedPreferencesNames={props.selectedSubgenres}
                                selectedPreferencesButtons={selectedSubgenres}
                                mostRecentlySelected={props.mostRecentlySelected}
                                roomForMorePreferences={props.roomForMorePreferences}
                                tooManySelectedPreferencesText={tooManySelectedPreferencesText}
                                height={250}
                                analysisStarted={false}
                />
            </div>
        </div>
    )

    function AddGenre(genre: string) {
        props.addGenre({
            value: genre,
            type: "genre"
        });
    }
    
    function RemoveGenre(genre: string) {
        props.removeGenre({
            value: genre,
            type: "genre"
        });
    }

    function AddSubgenre(subgenre: string) {
        props.addGenre({
            value: subgenre,
            type: "subgenre"
        });
    }

    function RemoveSubgenre(subgenre: string) {
        props.removeGenre({
            value: subgenre,
            type: "subgenre"
        });
    }
};