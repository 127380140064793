import React, {useEffect, useState} from "react";
import {Playlist} from "../Playlist";
import {ButtonSplitter, WizardButton, WizardCancelButton} from "./WizardButtons";
import {ErrorMessage} from "../../../../common/ErrorMessage";
import {PreferenceButton} from "../../common/PreferenceButton";
import PreferenceChoiceModule from "./PreferenceChoiceModule";
import {ReactComponent as Calendar} from '../../common/calendar.svg';
import {ReactComponent as Music} from '../../common/music.svg';
import {criteriaFormatter} from "../TextFormatter";
import {NumberOfAvailableCriteria} from "./Constants";
import {CombinatorialGenres} from "./CombinatorialGenres";
import {PlaylistDefinition} from "./PlaylistDefinition";
import {Criterion} from "./Criterion";
import {Genre} from "./Genre";

interface PlaylistPickerProps {
    availableDecadePlaylists: Playlist[];
    availableGenrePlaylists: Playlist[];
    criteria: Criterion[];
    addCriteria: (genres: string[], decade: number | undefined) => void;
    reset: () => void;
}

export const PlaylistPicker = (props: PlaylistPickerProps) => {
    const [selectedDecade, setSelectedDecade] = useState<string>();
    const [selectedGenres, setSelectedGenres] = useState<Genre[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [mostRecentlySelected, setMostRecentlySelected] = useState<string>();
    const [waitAndHoldMostRecentlySelected, setWaitAndHoldMostRecentlySelected] = useState<string[]>([]);
    useEffect(() => {
        setMostRecentlySelected(waitAndHoldMostRecentlySelected[0])
    }, [waitAndHoldMostRecentlySelected]);
    useEffect(() => {
        setMostRecentlySelected(waitAndHoldMostRecentlySelected[0])
    }, [waitAndHoldMostRecentlySelected]);

    let decadeButtons = props.availableDecadePlaylists
        .map(playlist =>
            <PreferenceButton
                mostRecentlyClicked={mostRecentlySelected === playlist.name}
                roomForMorePreferences={selectedDecade === undefined}
                key={playlist.name}
                preference={playlist.name}
                addSelectedPreferences={addDecade}
                removeSelectedPreferences={removeDecade}
                active={selectedDecade === playlist.name}
                tooManySelectedPreferencesText={"You cannot select more than 1 decade"}
                available={true}
            />);

    let currentCriterion = {
        genres: selectedGenres.map(genre => genre.value),
        decade: selectedDecade !== undefined ? Number(selectedDecade.replace("s", "")) : undefined
    };
    let criteriaNumber = props.criteria.length + 1;
    let addCriteriaButtonText = criteriaNumber === NumberOfAvailableCriteria ? "Add last criterion" : "Add criterion";
    
    return (
        <React.Fragment>
            <PlaylistDefinition float={true} currentCriterion={currentCriterion} criteria={props.criteria}/>
            <div className="picker">
                <h3>Add {criteriaFormatter(criteriaNumber)} criterion</h3>
                <p>A liked song is added to the playlist, if <b>any</b> of the criteria matches the song.</p>
                <p>A criterion matches the song, if <b>all</b> of the chosen genres and decade apply to the song.</p>
                <PreferenceChoiceModule
                    explainText={"Choose up to 5 genres for your criterion"}
                    subModule={<CombinatorialGenres
                        selectedGenres={selectedGenres.filter(genre => genre.type == "genre").map(genre => genre.value)}
                        selectedSubgenres={selectedGenres.filter(genre => genre.type == "subgenre").map(genre => genre.value)}
                        mostRecentlySelected={mostRecentlySelected}
                        availableGenrePlaylists={props.availableGenrePlaylists}
                        addGenre={addGenre}
                        removeGenre={removeGenre}
                        roomForMorePreferences={selectedGenres.length < 5}
                    />}
                    icon={<Music height={24} width={24}/>}/>
                <PreferenceChoiceModule
                    explainText={"Choose up to 1 decade for your criterion"}
                    subModule={<React.Fragment>{decadeButtons}</React.Fragment>}
                    icon={<Calendar height={24} width={24}/>}/>
                <div className="wizardConfirmationButtonContainer">
                    <WizardCancelButton onClick={() => reset()} text={"Back to overview"}/>
                    <ButtonSplitter/>
                    <WizardButton onClick={() => addCriteria()} text={addCriteriaButtonText}/>
                </div>
                <ErrorMessage errorMessage={errorMessage}/>
            </div>
        </React.Fragment>
    )

    function reset(): boolean {
        setErrorMessage(undefined);
        setSelectedDecade(undefined);
        setSelectedGenres([]);
        setMostRecentlySelected(undefined);
        props.reset();
        return true;
    }

    function addCriteria(): boolean {
        if (selectedGenres.length === 0 && selectedDecade === undefined) {
            setErrorMessage("You must at least pick either one genre or decade");
            return false;
        }
        
        props.addCriteria(selectedGenres.map(genre => genre.value), selectedDecade !== undefined ? Number(selectedDecade.replace("s", "")) : undefined);
        setErrorMessage(undefined);
        setSelectedDecade(undefined);
        setSelectedGenres([]);
        setMostRecentlySelected(undefined);
        return true;
    }

    function addGenre(genre: Genre) {
        if (selectedGenres.length >= 5) {
            if (genre.value !== mostRecentlySelected) {
                setMostRecentlySelected(genre.value);
            } else {
                setWaitAndHoldMostRecentlySelected([genre.value]);
                setMostRecentlySelected(undefined);
            }
            return;
        }

        setSelectedGenres(oldSelectedGenres => [...oldSelectedGenres, genre]);
    }

    function removeGenre(genre: Genre) {
        setMostRecentlySelected(undefined);
        setSelectedGenres(oldSelectedGenres => oldSelectedGenres.filter(oldGenre => oldGenre.value !== genre.value));
    }

    function addDecade(decade: string) {
        if (selectedDecade !== undefined) {
            setWaitAndHoldMostRecentlySelected([decade]);
            setMostRecentlySelected(undefined);
            return;
        }

        setSelectedDecade(decade);
    }

    function removeDecade(decade: string) {
        setMostRecentlySelected(undefined);
        setSelectedDecade(undefined);
    }
}