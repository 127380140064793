import './PremiumPopup.css';
import {AvailableIcon} from "../../../common/Icons";
import React, {useEffect, useRef, useState} from 'react';
import {ReactComponent as ClosePopup} from "../common/x.svg";
import {getPremiumPricing} from "../../../../api/payment/PaymentApi";
import {PremiumPricing} from "../../../../api/payment/PremiumPricing";
import {getId} from "../../../../api/user/UserApi";
import {Overlay} from "../../../common/Overlay";

interface PremiumPopup {
    shown: boolean;
    hide: () => void;
}

const PremiumPopup = (props: PremiumPopup) => {
    const ref: any = useRef(null);
    const [premiumPriceAmount, setPremiumPriceAmount] = useState<number | undefined>(undefined);
    const [premiumPriceCurrency, setPremiumPriceCurrency] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (props.shown && premiumPriceAmount === undefined && premiumPriceCurrency === undefined) {
            getPremiumPricing()
                .then((result: PremiumPricing) => {
                    setPremiumPriceAmount(result.amount);
                    setPremiumPriceCurrency(result.currency);
                });
        }
    }, [props.shown]);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                props.hide();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    if (!props.shown)
        return <React.Fragment/>

    if (premiumPriceAmount === undefined || premiumPriceCurrency === undefined)
        return <Overlay/>

    return (
        <div>
            <Overlay/>
            <div ref={ref} className="premiumPopup">

                <div className={"popupHeader"}>
                    <p>This playlist is only available to premium members. Become premium and get access to:</p>
                    <div className={"closePopup"}>
                        <ClosePopup className={"closePopupButton"} onClick={() => props.hide()}/>
                    </div>
                </div>
                <ul>
                    <li className="teaserAvailability">
                        <AvailableIcon/> Premium playlists
                    </li>
                    <li className="teaserAvailability">
                        <AvailableIcon/> Combination playlists
                    </li>
                    <li className="teaserAvailability">
                        <AvailableIcon/> All of your liked songs considered for the playlists
                    </li>
                    <li className="teaserAvailability">
                        <AvailableIcon/> Up to 100 managed playlists
                    </li>
                    <li className="teaserAvailability">
                        <AvailableIcon/> .. and more
                    </li>
                </ul>
                <p className="popupPrice">
                    {premiumPriceAmount} {premiumPriceCurrency}
                </p>
                <p className="popupPriceExplainer">per year billed annually</p>
                <div onClick={() => onUpgradeClick()} className="centerChild">
                    <button className="unselectable upgradeNowButton">Upgrade now</button>
                </div>
            </div>
        </div>
    )

    function onUpgradeClick() {
        getId()
            .then(userId => {
                // @ts-ignore
                Paddle.Setup({
                    seller: 175627
                });
                // @ts-ignore
                Paddle.Initialize({
                    token: 'live_0adb4cb26010f8b84208b9020d3'
                });
                // @ts-ignore
                Paddle.Checkout.open({
                    settings: {
                        theme: "light",
                        successUrl: 'https://www.likedsongsmanager.com/plans/success'
                    },
                    items: [
                        {
                            priceId: 'pri_01h9ne102e48tvht48ht0rgkta',
                            quantity: 1
                        }
                    ],
                    customData: {
                        user_id: userId
                    }
                });
                props.hide();
            }).then(() => window.scrollTo(0, 0));
    }
}

export default PremiumPopup;