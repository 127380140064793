import './PlaylistDefinition.css';
import React from "react";
import {Criterion} from "./Criterion";
import {CombinationExpression} from "../PrettifyCombination";

interface PlaylistDefinitionProps {
    currentCriterion?: Criterion;
    criteria: Criterion[];
    float: boolean;
}

export const PlaylistDefinition = (props: PlaylistDefinitionProps) => {
    let allCriteria = Array.from(props.criteria);
    if (props.currentCriterion !== undefined && (props.currentCriterion.genres.length > 0 || props.currentCriterion.decade !== undefined)) {
        allCriteria.push(props.currentCriterion);
    }

    let prettifiedCombination = allCriteria.length === 0
        ? 'The definition of your playlist will appear here.'
        : <CombinationExpression criteria={allCriteria} />;
    return (
        <React.Fragment>
            <div id={"combinationResultContainer"} className={props.float ? "float" : ""}>
                <div id="combinationResult" >
                    {prettifiedCombination}
                </div>
            </div>
        </React.Fragment>
    )
}
