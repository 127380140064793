import React from "react";
import './PreferenceChoiceModule.css';

interface PreferenceChoiceModuleProps {
    explainText: string;
    subModule: React.ReactElement;
    icon: React.ReactElement;
}

const PreferenceChoiceModule = (props: PreferenceChoiceModuleProps) => {
    return (
        <div className="preferenceChoiceModule">
            <div className="preferenceChoiceModuleHeader">
                <div className="preferenceIcon">
                    {props.icon}
                </div>
                <div className="preferenceChoiceModuleHeaderText">{props.explainText}</div>
            </div>
            <div className="preferenceManagementSubModule">
                {props.subModule}
            </div>
        </div>
    )
}

export default PreferenceChoiceModule;