import React from "react";

export const Cancellation = () => {
    return (
        <div className="pageContentSegment">
            <h1>Cancellation policy</h1>
            <i>Last modified: 30th August 2023.</i>
            <p>We want satisfied customers, not hostages. That's why we make it easy for you to cancel your Premium subscription.</p>
            <p>If you wish to cancel your Premium subscription go to <a href="/plans">Plans</a>.</p>
            <p>Please note that you need to cancel at least 72 hours before your next billing date to avoid paying for the next billing period.</p>
            <h2>What happens when you cancel a premium subscription?</h2>
            <p>Even if you cancel your subscription, you will still keep your premium subscription for the remainder of the subscription period.</p>
            <p>We don't automatically refund you for any time you may have left but if you haven't used your account in months or just started a new billing cycle, <a
                href="mailto:support@likedsongsmanager.com">contact us</a> for a fair refund. We'll treat you right.</p>
            <p>Adapted from the <a href="https://github.com/basecamp/policies">Basecamp open-source policies</a> / <a href="https://creativecommons.org/licenses/by/4.0/">CC BY
                4.0</a></p>
        </div>
    )
}