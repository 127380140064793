import './WizardButtons.css'
import React from "react";
import {Playlist} from "../Playlist";
import {SmallerCancelButton, SmallerConfirmationButton} from '../../../../common/Buttons';

interface WizardButtonProps {
    text: string;
    onClick: () => boolean;
}

export const WizardButton = (props: WizardButtonProps) => {
    return (
        <SmallerConfirmationButton
            onClick={() => click()}
            text={props.text}/>
    )

    function click() {
        if (!props.onClick())
            return;

        let wizardProgress = document.getElementById("combinationResult")
        if (wizardProgress === null)
            return;

        wizardProgress.scrollIntoView({block: "center"});
    }
}
export const WizardCancelButton = (props: WizardButtonProps) => {
    return (
        <SmallerCancelButton
            onClick={() => click()}
            text={props.text}/>
    )

    function click() {
        if (props.onClick() === false)
            return;

        var wizardProgress = document.getElementById("combinationResult")
        if (wizardProgress === null)
            return;

        wizardProgress.scrollIntoView({block: "center"});
    }
}

export const ButtonSplitter = () => {
    return (
        <div className="buttonSplitter"></div>
    )
}

interface WizardPreferenceButtonProps {
    playlist: Playlist;
    active: boolean;
    addSelectedPreferences: (playlist: Playlist) => void;
    removeSelectedPreferences: (playlist: Playlist) => void;
};