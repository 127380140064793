import React from "react";
import {ButtonSplitter, WizardButton, WizardCancelButton} from "./WizardButtons";
import {PlaylistDefinition} from "./PlaylistDefinition";
import {Criterion} from "./Criterion";

interface AddAnotherCriteriaProps {
    reset: () => boolean;
    createPlaylist: () => boolean;
    startNewCriteria: () => boolean;
    criteria: Criterion[];
}

const AddAnotherCriteria = (props: AddAnotherCriteriaProps) => {
    return (
        <React.Fragment>
            <PlaylistDefinition float={false} criteria={props.criteria}/>
            <div className="picker">
                <h3>Do you want to add another criterion?</h3>
                <p>A liked song is added to the playlist, if <b>any</b> of the criteria matches the song.</p>
                <p>A criterion matches the song, if <b>all</b> of the chosen genres and decade apply to the song.</p>
                <div className="wizardConfirmationButtonContainer">
                    <WizardCancelButton onClick={() => props.reset()} text={"Back to overview"}/>
                    <ButtonSplitter/>
                    <WizardButton onClick={() => props.createPlaylist()} text={"Create the playlist"}/>
                    <ButtonSplitter/>
                    <WizardButton onClick={() => props.startNewCriteria()} text={"Add another criterion"}/>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddAnotherCriteria;