import React, {useEffect, useRef, useState} from "react";
import {Playlist} from "../Playlist";
import AddAnotherCriteria from "./AddAnotherCriteria";
import {PlaylistDefinition} from "./PlaylistDefinition";
import {PlaylistNamer} from "./PlaylistNamer";
import {PlaylistPicker} from "./PlaylistPicker";
import './Wizard.css';
import {WizardProgress} from "./WizardProgress";
import {CSSTransition} from 'react-transition-group';
import {NumberOfAvailableCriteria} from "./Constants";
import {Criterion} from "./Criterion";

interface WizardProps {
    availableDecadePlaylists: Playlist[];
    availableGenrePlaylists: Playlist[];
    goToOverview: () => void;
}

const Wizard = (props: WizardProps) => {
    const [decidingToAddAnotherCriteria, setDecidingToAddAnotherCriteria] = useState<boolean>(false);
    const [criteria, setCriteria] = useState<Criterion[]>([]);
    const [currentCriteriaNumber, setCurrentCriteriaNumber] = useState<number>(0);
    const [previousCriteriaNumber, setPreviousCriteriaNumber] = useState<number>();
    const [createPlaylist, setCreatePlaylist] = useState<boolean>(false);
    
    let playlistPickerShown = !createPlaylist && !decidingToAddAnotherCriteria;
    let addAnotherCriteriaShown = !createPlaylist && decidingToAddAnotherCriteria;

    const playlistPickerRef = useRef(null);
    const addAnotherCriteriaRef = useRef(null);
    const namerRef = useRef(null);

    // Scroll to top after adding criteria, adding another criterion, and creating playlist
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [decidingToAddAnotherCriteria, createPlaylist, previousCriteriaNumber]);
    
    return (
        <div>
            <WizardProgress 
                currentCriteriaNumber={currentCriteriaNumber}
                previousCriteriaNumber={previousCriteriaNumber}
                createPlaylist={createPlaylist}
            />
            <CSSTransition
                timeout={{
                    exit: 0,
                    enter: 250,
                    appear: 250
                }}
                appear={true}
                unmountOnExit
                classNames="wizard"
                nodeRef={playlistPickerRef}
                in={playlistPickerShown}>
                <div ref={playlistPickerRef}>
                    <PlaylistPicker availableDecadePlaylists={props.availableDecadePlaylists}
                                    availableGenrePlaylists={props.availableGenrePlaylists}
                                    addCriteria={addCriteria}
                                    reset={reset}
                                    criteria={criteria}/>
                </div>
            </CSSTransition>
            <CSSTransition
                timeout={{
                    exit: 0,
                    enter: 250
                }}
                unmountOnExit
                classNames="wizard"
                nodeRef={addAnotherCriteriaRef}
                in={addAnotherCriteriaShown}>
                <div ref={addAnotherCriteriaRef}>
                    <AddAnotherCriteria
                        criteria={criteria}
                        createPlaylist={finishExpression}
                        startNewCriteria={startNewCriteria}
                        reset={reset}/>
                </div>
            </CSSTransition>
            <CSSTransition
                timeout={{
                    exit: 0,
                    enter: 250
                }}
                unmountOnExit
                classNames="wizard"
                nodeRef={namerRef}
                in={createPlaylist}>
                <div ref={namerRef}>
                    <PlaylistNamer reset={reset} criteria={criteria}/>
                </div>
            </CSSTransition>
        </div>
    )

    function startNewCriteria() {
        setDecidingToAddAnotherCriteria(false);
        return true;
    }

    function addCriteria(genres: string[], decade: number | undefined) {
        let criterion = {
            genres: genres,
            decade: decade
        }
        
        if (criteria.length !== NumberOfAvailableCriteria - 1) {
            setDecidingToAddAnotherCriteria(true);
        } else {
            finishExpression();
        }
        
        setPreviousCriteriaNumber(currentCriteriaNumber);
        
        if (currentCriteriaNumber + 1 != NumberOfAvailableCriteria) {
            setCurrentCriteriaNumber(currentCriteriaNumber + 1);
        }
        
        setCriteria((existingCriteria: Criterion[]) => [...existingCriteria, criterion]);
    }

    function finishExpression(): boolean {
        setPreviousCriteriaNumber(currentCriteriaNumber);
        setCreatePlaylist(true);
        return true;
    }

    function reset(): boolean {
        setCriteria([]);
        setDecidingToAddAnotherCriteria(false);
        props.goToOverview();
        return true;
    }
}
export default Wizard;