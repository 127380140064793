import './CurrentCombinatorialPreferences.css';
import React from "react";
import {ErrorCode} from "../../../../api/ApiError";
import {ErrorMessage} from "../../../common/ErrorMessage";
import {CombinatorialPreference} from "./CombinatorialPreference";
import {CombinationExpression} from "./PrettifyCombination";

interface CurrentCombinatorialPreferencesProps {
    deletePreference: (preference: CombinatorialPreference) => void;
    errorCode: ErrorCode | undefined;
    combinatorialPreferences: CombinatorialPreference[];
}

const CurrentCombinatorialPreferences = (props: CurrentCombinatorialPreferencesProps) => {
    return (
        <div>
            <CurrentPreferences errorCode={props.errorCode} deletePreference={props.deletePreference} combinatorialPreferences={props.combinatorialPreferences}/>
        </div>);
}

interface CurrentPreferencesProps {
    combinatorialPreferences: CombinatorialPreference[];
    deletePreference: (preference: CombinatorialPreference) => void;
    errorCode: ErrorCode | undefined;
};
const CurrentPreferences = (props: CurrentPreferencesProps) => {
    let currentPreferences = props.combinatorialPreferences
        .map((preference: CombinatorialPreference) => <CombinatorialPreferenceElement
            deletePreference={props.deletePreference}
            preference={preference}
            key={preference.id}/>)

    return (
        <div className="pageContentSegment">
            <h2>Your combination playlists</h2>
            {currentPreferences.length === 0 ? <div>You have no combination playlists.</div> :
                <table>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th className="definitionColumn">Definition</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {currentPreferences}
                    </tbody>
                </table>
            }
            <ErrorMessage errorCode={props.errorCode}/>
        </div>
    )
}

interface CombinatorialPreferenceProps {
    preference: CombinatorialPreference;
    deletePreference: (preference: CombinatorialPreference) => void;
}

const CombinatorialPreferenceElement = (props: CombinatorialPreferenceProps) => {
    return (
        <tr>
            <td>{props.preference.name}</td>
            <td className="definitionColumn">
                {
                    !props.preference.active
                        ? "Playlist was not deleted correctly. Please delete it again."
                        : <CombinationExpression criteria={props.preference.criteria}/>
                }</td>
            <td className="deleteColumn">
                <div onClick={() => props.deletePreference(props.preference)} className="deleteCombinationButton unselectable">
                    Delete
                </div>
            </td>
        </tr>
    )
}


export default CurrentCombinatorialPreferences;