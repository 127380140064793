import './WizardProgress.css';
import {useRef} from "react";
import {CSSTransition} from 'react-transition-group';
import {criteriaFormatter} from "../TextFormatter";
import {NumberOfAvailableCriteria} from "./Constants";

interface WizardProgressProps {
    previousCriteriaNumber: number | undefined;
    currentCriteriaNumber: number;
    createPlaylist: boolean;
}

export const WizardProgress = (props: WizardProgressProps) => {
    return (
        <div id="wizardProgress">
            <div className="wizardProgressPointAndTextContainer">
                <WizardProgressPointAndText
                    active={isCriteriaActive(getCriteriaPointNumber(1))}
                    text={`${criteriaFormatter(getCriteriaPointNumber(1))} Criterion`}
                    isBeyond={isBeyond(getCriteriaPointNumber(1))}
                    skipped={isPointSkipped(getCriteriaPointNumber(1))}/>
                <WizardProgressBetweenPoints
                    isBeyond={isBeyond(getCriteriaPointNumber(1))}
                    skipped={isProgressBetweenPointsSkipped(getCriteriaPointNumber(1))}/>
                <WizardProgressPointAndText
                    active={isCriteriaActive(getCriteriaPointNumber(2))}
                    text={`${criteriaFormatter(getCriteriaPointNumber(2))} Criterion`}
                    isBeyond={isBeyond(getCriteriaPointNumber(2))}
                    skipped={isPointSkipped(getCriteriaPointNumber(2))}/>
                <WizardProgressBetweenPoints
                    isBeyond={isBeyond(getCriteriaPointNumber(2))}
                    skipped={isProgressBetweenPointsSkipped(getCriteriaPointNumber(2))}/>
                <WizardProgressPointAndText
                    active={isCriteriaActive(getCriteriaPointNumber(3))}
                    text={`${criteriaFormatter(getCriteriaPointNumber(3))} Criterion`}
                    isBeyond={isBeyond(getCriteriaPointNumber(3))}
                    skipped={isPointSkipped(getCriteriaPointNumber(3))}/>
                <WizardProgressBetweenPoints
                    isBeyond={props.createPlaylist}
                    skipped={props.createPlaylist}/>
                <WizardProgressPointAndText
                    active={props.createPlaylist}
                    text={"Create Playlist"}
                    isBeyond={false}
                    skipped={props.createPlaylist}/>
            </div>
        </div>
    )
    
    function getCriteriaPointNumber(positionNumber: number): number {
        if (props.currentCriteriaNumber <= 2) {
            return positionNumber;
        }

        let pointNumber = props.currentCriteriaNumber + positionNumber - 2;
        if (pointNumber > NumberOfAvailableCriteria) {
            return NumberOfAvailableCriteria;
        }

        return pointNumber;
    }

    function isProgressBetweenPointsSkipped(pointNumber: number): boolean {
        if (props.previousCriteriaNumber === undefined)
            return false;

        return (hasSkippedAPoint() && pointNumber < props.currentCriteriaNumber && pointNumber > props.previousCriteriaNumber)
    }

    function isPointSkipped(pointNumber: number): boolean {
        if (props.previousCriteriaNumber === undefined)
            return false;

        return (hasSkippedAPoint() && pointNumber <= props.currentCriteriaNumber && pointNumber > props.previousCriteriaNumber + 1)
    }

    function hasSkippedAPoint(): boolean {
        if (props.previousCriteriaNumber === undefined)
            return false;

        return props.currentCriteriaNumber > props.previousCriteriaNumber;
    }

    function isBeyond(pointNumber: number): boolean {
        return props.currentCriteriaNumber + 1 > pointNumber || props.createPlaylist;
    }

    function isCriteriaActive(pointNumber: number) {
        return props.currentCriteriaNumber + 1 == pointNumber && !props.createPlaylist;
    }
}

interface WizardProgressPointAndTextProps {
    text: string;
    active: boolean;
    isBeyond: boolean;
    skipped: boolean;
}

const WizardProgressPointAndText = (props: WizardProgressPointAndTextProps) => {
    let textClasses = props.active ? "wizardProgressPointText wizardProgressPointTextActive" : "wizardProgressPointText";
    textClasses = props.isBeyond ? `${textClasses} wizardProgressPointTextBeyond` : textClasses;

    var textElements = props.text.split(" ").map(textPart => <div key={textPart} className={textClasses}>{textPart}</div>)
    return (
        <div className="wizardProgressContainer">
            <WizardProgressPoint active={props.active} isBeyond={props.isBeyond} skipped={props.skipped}/>
            <div className="wizardProgressPointTextContainer">
                {textElements}
            </div>
        </div>
    )
}

interface WizardProgressPointProps {
    active: boolean;
    isBeyond: boolean;
    skipped: boolean;
}

const WizardProgressPoint = (props: WizardProgressPointProps) => {
    const nodeRef = useRef(null);
    let classes = "wizardProgressPoint";
    if (props.skipped)
        classes += " wizardProgressPointSkipped";

    return (
        <CSSTransition
            nodeRef={nodeRef}
            in={props.active || props.isBeyond || props.skipped}
            appear={true}
            classNames="wizardProgressPoint"
            timeout={{
                appear: 0,
                enter: 800
            }}>
            <div ref={nodeRef} className={classes}/>
        </CSSTransition>
    )
}

interface WizardProgressBetweenPointsProps {
    isBeyond: boolean;
    skipped: boolean;
}

const WizardProgressBetweenPoints = (props: WizardProgressBetweenPointsProps) => {
    const nodeRef = useRef(null);
    let classes = "wizardProgressBetweenPointsAnimation";
    if (props.skipped)
        classes += " wizardProgressBetweenPointsAnimationSkipped";

    return (
        <CSSTransition
            nodeRef={nodeRef}
            in={props.isBeyond || props.skipped}
            classNames="wizardProgressBetweenPoints"
            timeout={1000}>
            <div className="wizardProgressBetweenPoints">
                <div ref={nodeRef} className={classes}/>
            </div>
        </CSSTransition>
    )
}